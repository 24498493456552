<script setup lang="ts"></script>
<template>
  <div
    class="text-neutral-white bg-neutral-black gap-xs positive-padding relative bottom-0 flex w-full flex-col items-start justify-start py-4"
  >
    <p class="text-medium-7">
      {{ $ts('cartPage.footer.shippingInfo') }}
    </p>
    <p class="text-light-7">
      {{ $ts('cartPage.footer.copyright', { year: new Date().getFullYear() }) }}
    </p>
  </div>
</template>
